enum Breakpoint {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1400,
}

const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

const useScreen = () => {
  const { width } = useWindowSize();

  const breakpoint = computed(() => {
    if (width.value < Breakpoint.sm) {
      return 'xs';
    } else if (width.value < Breakpoint.md) {
      return 'sm';
    } else if (width.value < Breakpoint.lg) {
      return 'md';
    } else if (width.value < Breakpoint.xl) {
      return 'lg';
    } else if (width.value < Breakpoint.xxl) {
      return 'xl';
    } else {
      return 'xxl';
    }
  });

  const largerThan = (name: string) =>
    breakpoints.indexOf(breakpoint.value) >= breakpoints.indexOf(name);

  const isDesktop = computed(() => largerThan('xl'));
  const isNotDesktop = computed(() => !isDesktop.value);

  return { breakpoint, largerThan, isDesktop, isNotDesktop };
};

export { useScreen };
